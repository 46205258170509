import React, { useState, useContext } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { post } from "../../api";
import {
  ALARM,
  ALARM_ACKNOWLEDGED,
  ASSET_FILE_ADD,
  ASSET_CREATED,
  ASSET_UPDATED,
  ASSET_NOTE,
  INSPECTION_CREATED,
  INSPECTION_FILE_ADD,
  DEVICE_ASSIGNED,
  DEVICE_UNASSIGNED,
  ASSET_MAINTENACE_DISABLE,
  ASSET_MAINTENACE_ENABLE,
  FALSE_ALERT,
  DEVICE_REPAIRED,
  WORK_IN_PROGRESS,
  WORK_COMPLETE,
  LICENSE_CREATED,
  LICENSE_DEACTIVATED,
  LICENSE_TRASFERRED,
  LICENSE_UPDATED,
  LICENSE_EXPIRED,
  RESUMED_CHECK_IN,
} from "../../constants/events";

import ActivityItem from "../ActivityItem";
import DeviceStatus from "../DeviceStatus";
import AssetCard from "../AssetCard";
import Avatar from "../Avatar";
import CheckboxWithLabel from "../CheckboxWithLabel";
import DeviceCard from "../DeviceCard";
import InspectionCard from "../InspectionCard";
import Frame from "../Frame";
import Label from "../Label";
import Pagination from "../Pagination";
import Popover from "../Popover";
import Loading from "../Loading";
import { SYS_ADMIN, TENANT_ADMIN, CUSTOMER_USER } from "../../constants/roles";
import styles from "./AssetActivity.module.scss";
import EventTypeChanged from "../EventTypeChanged";
import { IMPACT } from "../../constants/statuses";
import Button from "../Button";
import { isAuthorized, hasPermission } from "../../helpers/authorization";
import { AuthContext } from "../../contexts/Auth";
import LoadingFrame from "../LoadingFrame";
import Icon from "../Icon";
import { useConfirm } from "material-ui-confirm";
export default function AssetActivity({
  events,
  loading,
  total,
  filters,
  page,
  perpage = 500,
  setFilters,
  setCurrentPage,
  onRefresh,
}) {
  const isFiltering = Object.values(filters).some((v) => !v);
  const [sending, setSending] = useState(false);
  const { state } = useContext(AuthContext);
  const [saving, setSaving] = useState(false);
  const [savingID, setSavingID] = useState(0);
  const confirm = useConfirm();
  async function SendReport(id) {
    setSending(true);
    await post(`/inspection/${id}/report`);
    setSending(false);
  }
  function decodeHTML(content) {
    return (
      <div
        className="content"
        dangerouslySetInnerHTML={{ __html: content }}
      ></div>
    );
  }

  function stateDecoder(state) {
    switch (state) {
      case FALSE_ALERT:
        return "false alert";
      case WORK_IN_PROGRESS:
        return "work in progress";
      case WORK_COMPLETE:
        return "repair complete";
      case ALARM:
        return "impact";
    }
  }

  function changeEventConfirmation(event, to_type) {
    confirm({
      description:
        "Are you sure you want to change this impact alarm from " +
        stateDecoder(event.type) +
        " to " +
        stateDecoder(to_type) +
        "?",
      title: null,
      dialogProps: { fullScreen: false },
      cancellationButtonProps: { color: "error", disableRipple: true },
      confirmationButtonProps: { color: "primary", disableRipple: true },
    })
      .then(() => {
        changeEvent(event, to_type);
      })
      .catch(() => {
        /* ... */
      });
  }

  async function changeEvent(event, to_type) {
    setSaving(true);
    setSavingID(event.id);
    event.type = to_type;
    const saveEvent = await post(`/events/${event.id}`, event);
    onRefresh(saveEvent);
    setSaving(false);
    setSavingID(0);
  }

  async function ToggleReportVisibility(event_id, state) {
    var res = await post(`/events/${event_id}/show_in_report/${state}`);
    onRefresh(false);
  }

  function renderHideFromReport(event) {
    return (
      <span className={styles.hideFromReportContainer}>
        <CheckboxWithLabel
          fullwidth={true}
          label="Show in report"
          checked={event.show_in_report}
          hideFromReport={true}
          onChange={(e) => {
            ToggleReportVisibility(event.id, e.target.checked);
          }}
        />
      </span>
    );
  }

  const isItTheMostRecentEvent = (event) => {
    return event.edit;
  };
  function modifyOptions(event) {
    if (isItTheMostRecentEvent(event) === true) {
      if (
        event.type === ALARM ||
        event.type === FALSE_ALERT ||
        event.type === WORK_IN_PROGRESS ||
        event.type === WORK_COMPLETE
      ) {
        if (
          (event.alarm !== undefined &&
            event.type === ALARM &&
            event.alarm.type === IMPACT) ||
          event.type === FALSE_ALERT ||
          event.type === WORK_IN_PROGRESS ||
          event.type === WORK_COMPLETE
        ) {
          if (
            isAuthorized(state.user.roles, [
              SYS_ADMIN,
              TENANT_ADMIN,
              CUSTOMER_USER,
            ]) &&
            (hasPermission(state.user.perms, "ADMIN") ||
              hasPermission(state.user.perms, "ASSET_C"))
          ) {
            return (
              <>
                <Popover
                  title={"Click to edit event type"}
                  icon="edit"
                  align="right"
                  alt="Click to edit event type"
                  theme={"primary"}
                  width={300}
                >
                  {saving ? (
                    <LoadingFrame />
                  ) : (
                    <>
                      <p className={styles.text}>Change to</p>
                      {/* event.type !== ALARM && <div className={styles.wrap}><Button icon={icon_small(0)} onClick={() => changeEvent(event, ALARM)} >Alarm</Button></div> */}
                      {event.type !== FALSE_ALERT && (
                        <div className={styles.wrap}>
                          <Button
                            icon={icon_small(1)}
                            onClick={() =>
                              changeEventConfirmation(event, FALSE_ALERT)
                            }
                          >
                            False alert
                          </Button>
                        </div>
                      )}
                      {event.type !== WORK_IN_PROGRESS && (
                        <div className={styles.wrap}>
                          <Button
                            icon={icon_small(3)}
                            onClick={() =>
                              changeEventConfirmation(event, WORK_IN_PROGRESS)
                            }
                          >
                            Work in progress
                          </Button>
                        </div>
                      )}
                      {event.type !== WORK_COMPLETE && (
                        <div className={styles.wrap}>
                          <Button
                            icon={icon_small(4)}
                            onClick={() =>
                              changeEventConfirmation(event, WORK_COMPLETE)
                            }
                          >
                            Repair complete
                          </Button>
                        </div>
                      )}
                    </>
                  )}
                </Popover>
                {((event.alarm !== undefined &&
                  event.type === ALARM &&
                  event.alarm.type === IMPACT) ||
                  event.type === WORK_IN_PROGRESS ||
                  event.type === WORK_COMPLETE) &&
                  event.inspection === undefined && (
                    <>
                      <div className={styles.spacer} />
                      <Link
                        to={`/assets/${event.asset.id}/inspection?event=${event.id}&asset=${event.asset.id}`}
                        title="Click to add new report"
                      >
                        <Button
                          size="s"
                          alt={"New report"}
                          theme="primary"
                          icon={"add"}
                        />
                      </Link>
                    </>
                  )}
              </>
            );
          }
        }
      }
    } else {
      if (
        isAuthorized(state.user.roles, [
          SYS_ADMIN,
          TENANT_ADMIN,
          CUSTOMER_USER,
        ]) &&
        (hasPermission(state.user.perms, "ADMIN") ||
          hasPermission(state.user.perms, "ASSET_C"))
      ) {
        if (
          (event.alarm !== undefined &&
            event.type === ALARM &&
            event.alarm.type === IMPACT) ||
          event.type === WORK_IN_PROGRESS ||
          event.type === WORK_COMPLETE
        ) {
          return (
            event.inspection === undefined && (
              <>
                <Link
                  to={`/assets/${event.asset.id}/inspection?event=${event.id}&asset=${event.asset.id}`}
                  title="Click to add new report"
                >
                  <Button
                    size="s"
                    alt={"New report"}
                    theme="primary"
                    icon={"add"}
                  />
                </Link>
              </>
            )
          );
        }
      }
    }
  }
  function icon_small(type) {
    var color = "#ffb217";
    const green = "#03b106";
    const orange = "#ffb217";
    const red = "#ee0e0e";
    const gray = "#777777";
    var selectedName = "done";
    switch (type) {
      case 0:
        selectedName = "error_outline";
        color = red;
        break;
      case 1:
        selectedName = "error";
        color = red;
        break;
      case 2:
        selectedName = "refresh";
        color = orange;
        break;
      case 3:
        selectedName = "build";
        color = gray;
        break;
      case 4:
        selectedName = "done";
        color = green;
        break;
      default:
        break;
    }
    return (
      <div style={{ color }}>
        <Icon name={selectedName} size={20} />
      </div>
    );
  }
  function _renderActvityItem(event) {
    const user = `${event.user.first_name} ${event.user.last_name}`;
    const update_user =
      event.update_user_id &&
      event.update_user &&
      `${event.update_user.first_name} ${event.update_user.last_name}`;
    let author = <Avatar name={user} />;
    let title = "";
    let body = "";
    let hideFromReport = false;
    let update_author = update_user;
    let action = false;
    let attachments = event.files;

    switch (event.type) {
      case ALARM:
        title = `An alarm was triggered on this asset.`;
        body =
          event && event.alarm && event.alarm.type ? (
            <DeviceStatus
              status={event && event.alarm && event.alarm.type}
              body={event.alarm && event.alarm.body}
            />
          ) : (
            <DeviceStatus status={"IMPACT"} />
          );
        action = modifyOptions(event);
        hideFromReport =
          event &&
          event.alarm &&
          event.alarm.type === IMPACT &&
          renderHideFromReport(event);
        break;

      case ALARM_ACKNOWLEDGED:
        title = (
          <>
            <b>{user}</b> acknowledged an alarm on this asset.
          </>
        );
        break;

      case INSPECTION_CREATED:
        title = (
          <>
            <b>{user}</b> added an inspection on this asset.
          </>
        );
        body = event.inspection && (
          <InspectionCard inspection={event.inspection} />
        );
        action = (
          <>
            {isAuthorized(state.user.roles, [
              SYS_ADMIN,
              TENANT_ADMIN,
              CUSTOMER_USER,
            ]) &&
              (hasPermission(state.user.perms, "ADMIN") ||
                hasPermission(state.user.perms, "ASSET_C")) && (
                <>
                  <span title={"Click to edit this report"}>
                    {event.inspection && (
                      <Link
                        to={`/assets/${event.asset_id}/inspection/${event.inspection.id}/edit`}
                      >
                        {" "}
                        <Button
                          isLoading={sending}
                          size="s"
                          theme="primary"
                          icon="edit"
                          alt="Edit"
                        />
                      </Link>
                    )}
                  </span>
                  <div className={styles.spacer}></div>
                </>
              )}

            {event.inspection && (
              <span title={"Click to send this report via email"}>
                <Button
                  isLoading={sending}
                  onClick={() => {
                    confirm({
                      description:
                        "Are you sure you want to send this report via email? This report will be emailed to everyone in the group.",
                      title: null,
                      dialogProps: { fullScreen: false },
                      cancellationButtonProps: {
                        color: "error",
                        disableRipple: true,
                      },
                      confirmationButtonProps: {
                        color: "primary",
                        disableRipple: true,
                      },
                    })
                      .then(() => {
                        SendReport(event.inspection.id);
                      })
                      .catch(() => {
                        /* ... */
                      });
                  }}
                  size="s"
                  theme="primary"
                  icon="email"
                  alt="Send Report"
                />
              </span>
            )}
          </>
        );
        break;

      case INSPECTION_FILE_ADD:
        title = (
          <>
            <b>{user}</b> added a file from during an inspection on this asset.
          </>
        );
        break;

      case LICENSE_CREATED:
        title = (
          <>
            <b>{user}</b> LICENSE_CREATED.
          </>
        );
        break;
      case LICENSE_DEACTIVATED:
        title = (
          <>
            <b>{user}</b> LICENSE_DEACTIVATED.
          </>
        );
        break;
      case LICENSE_TRASFERRED:
        title = (
          <>
            <b>{user}</b> transferred the subscription from another device.
          </>
        );
        body = decodeHTML(event.body);
        break;
      case LICENSE_UPDATED:
        title = "License was updated";
        body = <DeviceStatus status={LICENSE_UPDATED} />;
        break;
      case LICENSE_EXPIRED:
        title = "Expired subscription";
        action = (
          <>
            {" "}
            {isAuthorized(state.user.roles, [
              SYS_ADMIN,
              TENANT_ADMIN,
              CUSTOMER_USER,
            ]) &&
              (hasPermission(state.user.perms, "ADMIN") ||
                hasPermission(state.user.perms, "ASSET_C")) && (
                <>
                  <span title={"Click to edit this note"}>
                    <Link to={`/customer/subscripion/new`}>
                      {" "}
                      <Button
                        theme="danger"
                        isLoading={sending}
                        size="s"
                        icon="shop"
                        alt="Purchase"
                      />
                    </Link>
                  </span>
                  &nbsp;
                </>
              )}
          </>
        );
        break;
      case ASSET_FILE_ADD:
        title = (
          <>
            <b>{user}</b> uploaded an installation photo for this asset.
          </>
        );
        break;

      case ASSET_CREATED:
        title = (
          <>
            <b>{user}</b> created this asset.
          </>
        );
        body = <AssetCard asset={event.asset} />;
        break;

      case ASSET_UPDATED:
        title = (
          <>
            <b>{user}</b> updated information on this asset.
          </>
        );
        break;

      case ASSET_NOTE:
        title = (
          <>
            <b>{user}</b> added a note to this asset.
          </>
        );
        body = decodeHTML(event.body);
        action = (
          <>
            {" "}
            {isAuthorized(state.user.roles, [
              SYS_ADMIN,
              TENANT_ADMIN,
              CUSTOMER_USER,
            ]) &&
              (hasPermission(state.user.perms, "ADMIN") ||
                hasPermission(state.user.perms, "ASSET_C")) && (
                <>
                  <span title={"Click to edit this note"}>
                    <Link
                      to={`/assets/${event.asset_id}/note/${event.id}/edit`}
                    >
                      {" "}
                      <Button
                        isLoading={sending}
                        size="s"
                        theme="primary"
                        icon="edit"
                        alt="Edit"
                      />
                    </Link>
                  </span>
                  &nbsp;
                </>
              )}
          </>
        );
        //if(event.body==="" && attachments==="") return;
        break;
      case ASSET_MAINTENACE_ENABLE:
        title = (
          <>
            <b>{user}</b> enabled the maintenance mode
          </>
        );
        body = event.body;
        break;
      case ASSET_MAINTENACE_DISABLE:
        title = (
          <>
            <b>{user}</b> disabled the maintenance mode
          </>
        );
        body = event.body;
        break;
      case DEVICE_ASSIGNED:
        title = (
          <>
            <b>{user}</b> installed a device on this asset.
          </>
        );
        body = <DeviceCard device={event.device} />;
        break;

      case DEVICE_UNASSIGNED:
        title = (
          <>
            <b>{user}</b> removed a device from this asset.
          </>
        );
        body = <DeviceCard device={event.device} />;
        break;
      case FALSE_ALERT:
        title = `An alarm was triggered on this asset.`;
        body = <EventTypeChanged from={IMPACT} to={FALSE_ALERT} />;
        action = modifyOptions(event);
        break;
      case DEVICE_REPAIRED:
        title = `An alarm was triggered on this asset.`;
        body = <EventTypeChanged from={IMPACT} to={DEVICE_REPAIRED} />;
        hideFromReport = renderHideFromReport(event);
        break;

      case WORK_IN_PROGRESS:
        title = `An alarm was triggered on this asset.`;
        body = <EventTypeChanged from={IMPACT} to={WORK_IN_PROGRESS} />;
        action = modifyOptions(event);
        hideFromReport = renderHideFromReport(event);
        break;

      case WORK_COMPLETE:
        title = `An alarm was triggered on this asset.`;
        body = <EventTypeChanged from={IMPACT} to={WORK_COMPLETE} />;
        action = modifyOptions(event);
        hideFromReport = renderHideFromReport(event);
        break;

      case RESUMED_CHECK_IN:
        title = "Check-in resumed";
        body = <DeviceStatus status={RESUMED_CHECK_IN} />;
        break;
      default:
        return <>{event.type}</>;
    }
    if (
      isAuthorized(state.user.roles, [SYS_ADMIN, TENANT_ADMIN]) &&
      hasPermission(state.user.perms, "ADMIN")
    ) {
      action = (
        <>
          <Link to={`/assets/${event.asset_id}/event/${event.id}/edit`}>
            <Button size="s" theme="primary" icon="edit" alt="Edit event" />
          </Link>
          {/*<Button loading={sending} onClick={() => deleteEvent(event)} size="s" theme='danger' icon='delete' alt="Delete event" />*/}
        </>
      );
    }
    return savingID === event.id && savingID ? (
      <LoadingFrame />
    ) : (
      <ActivityItem
        key={event.id}
        author={author}
        title={title}
        body={body}
        hideFromReport={hideFromReport}
        action={action}
        attachments={attachments}
        date={moment(event.created_time).format("MMMM Do YYYY [at] h:mma")}
        updated={
          event.updated_time && updateMessageForEvent(event, update_author)
        }
        assetId={event.asset_id}
      />
    );
  }
  function updateMessageForEvent(event, updateAuthor) {
    if (!updateAuthor) return "";
    switch (event.type) {
      case FALSE_ALERT:
        return `Impact alarm updated to false alert by ${updateAuthor} at ${moment(
          event.updated_time
        ).format("MMM Do YYYY [at] h:mma")}`;
      case WORK_IN_PROGRESS:
        return `Impact alarm updated to work in progress by ${updateAuthor} at ${moment(
          event.updated_time
        ).format("MMM Do YYYY [at] h:mma")}`;
      case WORK_COMPLETE:
        return `Impact alarm updated to repair complete by ${updateAuthor} at ${moment(
          event.updated_time
        ).format("MMM Do YYYY [at] h:mma")}`;
      case INSPECTION_CREATED:
        return `Edited by ${updateAuthor} at ${moment(
          event.updated_time
        ).format("MMM Do YYYY [at] h:mma")}`;
      case ASSET_NOTE:
        return `Edited by ${updateAuthor} at ${moment(
          event.updated_time
        ).format("MMM Do YYYY [at] h:mma")}`;
      default:
        return "";
    }
  }
  return (
    <Frame padding={0}>
      <div className={styles.filterBar}>
        <Popover
          icon="filter_list"
          text="Filter activity"
          flag={isFiltering}
          align="left"
          width={300}
        >
          <Label text="Activity types" theme="light" />

          <CheckboxWithLabel
            checked={filters[INSPECTION_CREATED]}
            onChange={(e) =>
              setFilters({
                ...filters,
                INSPECTION_CREATED: e.target.checked,
                INSPECTION_FILE_ADD: e.target.checked,
              })
            }
            label="Inspections"
          />

          <CheckboxWithLabel
            checked={filters[ASSET_NOTE]}
            onChange={(e) =>
              setFilters({ ...filters, ASSET_NOTE: e.target.checked })
            }
            label="Notes"
          />
          <CheckboxWithLabel
            checked={filters[ASSET_CREATED]}
            onChange={(e) =>
              setFilters({ ...filters, ASSET_CREATED: e.target.checked })
            }
            label="Asset created"
          />
          <CheckboxWithLabel
            checked={filters[ASSET_UPDATED]}
            onChange={(e) =>
              setFilters({ ...filters, ASSET_UPDATED: e.target.checked })
            }
            label="Asset updated"
          />
          <CheckboxWithLabel
            checked={filters[DEVICE_ASSIGNED]}
            onChange={(e) =>
              setFilters({ ...filters, DEVICE_ASSIGNED: e.target.checked })
            }
            label="Device installed on asset"
          />
          <CheckboxWithLabel
            checked={filters[DEVICE_UNASSIGNED]}
            onChange={(e) =>
              setFilters({ ...filters, DEVICE_UNASSIGNED: e.target.checked })
            }
            label="Device removed from asset"
          />
          <CheckboxWithLabel
            checked={filters[ASSET_FILE_ADD]}
            onChange={(e) =>
              setFilters({ ...filters, ASSET_FILE_ADD: e.target.checked })
            }
            label="Installation photo added"
          />

          <CheckboxWithLabel
            checked={filters[ALARM_ACKNOWLEDGED]}
            onChange={(e) =>
              setFilters({ ...filters, ALARM_ACKNOWLEDGED: e.target.checked })
            }
            label="Alarm acknowledged"
          />
          <CheckboxWithLabel
            checked={filters[ALARM]}
            onChange={(e) =>
              setFilters({ ...filters, ALARM: e.target.checked })
            }
            label="Alarms"
          />

          <CheckboxWithLabel
            checked={filters[FALSE_ALERT]}
            onChange={(e) =>
              setFilters({ ...filters, FALSE_ALERT: e.target.checked })
            }
            label="False alarm"
          />
          <CheckboxWithLabel
            checked={filters[DEVICE_REPAIRED]}
            onChange={(e) =>
              setFilters({ ...filters, DEVICE_REPAIRED: e.target.checked })
            }
            label="Device repaired"
          />
          <CheckboxWithLabel
            checked={filters[WORK_IN_PROGRESS]}
            onChange={(e) =>
              setFilters({ ...filters, WORK_IN_PROGRESS: e.target.checked })
            }
            label="Work in progress"
          />
          <CheckboxWithLabel
            checked={filters[WORK_COMPLETE]}
            onChange={(e) =>
              setFilters({ ...filters, WORK_COMPLETE: e.target.checked })
            }
            label="Work complete"
          />
          <CheckboxWithLabel
            checked={filters[LICENSE_TRASFERRED]}
            onChange={(e) =>
              setFilters({ ...filters, LICENSE_TRASFERRED: e.target.checked })
            }
            label="Transferred subscription"
          />
          <CheckboxWithLabel
            checked={filters[LICENSE_EXPIRED]}
            onChange={(e) =>
              setFilters({ ...filters, LICENSE_EXPIRED: e.target.checked })
            }
            label="Expired subscription"
          />
          <CheckboxWithLabel
            checked={filters[LICENSE_UPDATED]}
            onChange={(e) =>
              setFilters({ ...filters, LICENSE_UPDATED: e.target.checked })
            }
            label="License updated"
          />
          <CheckboxWithLabel
            checked={filters[RESUMED_CHECK_IN]}
            onChange={(e) =>
              setFilters({ ...filters, RESUMED_CHECK_IN: e.target.checked })
            }
            label="Resumed check-in"
          />
        </Popover>

        <div className={styles.status}>
          {loading && (
            <div className={styles.loading}>
              <Loading color="gray" />
            </div>
          )}

          <span className={styles.totalText}>
            {total ? total : 0} activity item{total !== 1 && "s"}
          </span>
        </div>
      </div>
      <div className={styles.events}>
        {events.map((event) => _renderActvityItem(event))}
      </div>

      <Pagination
        total={total}
        perPage={perpage}
        currentPage={page}
        onPageChange={(page) => setCurrentPage(page)}
      />
    </Frame>
  );
}
