import React, { useState, useEffect, useContext } from "react";
import { useFormState } from "react-use-form-state";
import { differenceBy } from "lodash";
import history from "../../helpers/history";
import validate from "../../helpers/validate";
import { get, post, destroy, put } from "../../api";
import { AuthContext } from "../../contexts/Auth";
import Button from "../../components/Button";
import Frame from "../../components/Frame";
import FrameFooter from "../../components/FrameFooter";
import FrameHeader from "../../components/FrameHeader";
import Label from "../../components/Label";
import LoadingFrame from "../../components/LoadingFrame";
import Space from "../../components/Space";
import TextInput from "../../components/TextInput";
import styles from "./UserGroupDetail.module.scss";
import InputRow from "../../components/InputRow";
import Select from "../../components/Select";
import { useConfirm } from "material-ui-confirm";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { hasPermission } from "../../helpers/authorization";
import Chip from "@mui/material/Chip";
import AddNewChip from "../../components/AddNewChip/AddNewChip";

export default function GroupDetail({ match: { params } }) {
  const { state } = useContext(AuthContext);
  const [formState, { text }] = useFormState();
  const valid = validate(formState, {
    required: ["name"],
  });
  const isGroupAdmin = hasPermission(state.user.perms, "ADMIN");
  const GROUP_USERS = 0,
    GROUP_ASSETS = 1;
  const [displayContent, setDisplayContent] = useState(GROUP_ASSETS);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [group, setGroup] = useState(null);
  const [groupUsers, setGroupUsers] = useState([]);
  const [groupAssets, setGroupAssets] = useState([]);
  const [nonGroupAssets, setNonGroupAssets] = useState([]);
  const [nonGroupUsers, setNonGroupUsers] = useState([]);
  const [usersToAdd, setUsersToAdd] = useState([]);
  const [usersToRemove, setUsersToRemove] = useState([]);
  const [assetsToAdd, setAssetsToAdd] = useState([]);
  const [assetsToRemove, setAssetsToRemove] = useState([]);

  const confirm = useConfirm();
  const filter = createFilterOptions();
  function saveRequired() {
    return (
      usersToAdd.length > 0 ||
      usersToRemove.length > 0 ||
      assetsToAdd.length > 0 ||
      assetsToRemove.length > 0 ||
      group?.primary_contact_id != formState.values.primary_contact_id ||
      formState.values.name != group?.name
    );
  }

  useEffect(() => {
    fetchGroup();
    fetchAssets();
    isGroupAdmin && fetchUsers();
  }, []);

  async function fetchAssets() {
    setLoading(true);
    const [groupAssets, nonGroupAssets] = await Promise.all([
      get(`/groups/${params.groupId}/assets`),
      isGroupAdmin
        ? get(`/customers/${state.user.customer_id}/assets`)
        : get("/assets"),
    ]);
    setGroupAssets(groupAssets);

    const uniq = differenceBy(
      isGroupAdmin ? nonGroupAssets : nonGroupAssets.results,
      groupAssets,
      "id"
    );
    setNonGroupAssets(uniq);
    setLoading(false);
  }

  async function fetchGroup() {
    setLoading(true);
    const [group] = await Promise.all([get(`/groups/${params.groupId}`)]);
    group && formState.setField("name", group.name);
    group &&
      formState.setField("primary_contact_id", group?.primary_contact_id);
    group && setGroup(group);
  }

  async function fetchUsers() {
    setLoading(true);
    const [groupUsers, nonGroupUsers] = await Promise.all([
      get(`/groups/${params.groupId}/users`),
      get("/users", { customer_id: state.user.customer_id, include_groups: 1 }),
    ]);

    const uniq = differenceBy(nonGroupUsers.results, groupUsers.results, "id");
    setGroupUsers(groupUsers.results);
    setNonGroupUsers(uniq);
  }

  async function save() {
    setSaving(true);

    usersToAdd &&
      (await Promise.all(
        usersToAdd.map(async (userToAdd) => {
          await addUserToGroup(userToAdd.id);
        })
      ));
    usersToRemove &&
      (await Promise.all(
        usersToRemove.map(async (userToRemove) => {
          await removeUserFromGroup(userToRemove.id);
        })
      ));
    assetsToAdd &&
      (await Promise.all(
        assetsToAdd.map(async (assetToAdd) => {
          await addAssetToGroup(assetToAdd.id);
        })
      ));
    assetsToRemove &&
      (await Promise.all(
        assetsToRemove.map(async (assetToRem) => {
          await removeAssetFromGroup(assetToRem.id);
        })
      ));

    await put(`/groups/${params.groupId}`, {
      ...group,
      name: formState.values.name,
    });

    group?.primary_contact_id != formState.values.primary_contact_id &&
      (await post(
        `/groups/${params.groupId}/primary_contact/${formState.values.primary_contact_id}`
      ));
    isGroupAdmin && fetchUsers();
    fetchGroup();
    fetchAssets();
    setAssetsToAdd([]);
    setAssetsToRemove([]);
    setUsersToAdd([]);
    setUsersToRemove([]);
    setSaving(false);
  }

  function deleteGroupConfirmaton() {
    confirm({
      description: "Are you sure you want to remove this group?",
      title: null,
      dialogProps: { fullScreen: false },
      cancellationButtonProps: { color: "error", disableRipple: true },
      confirmationButtonProps: { color: "primary", disableRipple: true },
    })
      .then(() => {
        deleteGroup();
      })
      .catch(() => {
        /* ... */
      });
  }

  async function deleteGroup() {
    setDeleting(true);
    await destroy(`/groups/${params.groupId}`);
    history.push(`/customer/groups`);
  }

  async function addUserToGroup(userId) {
    await post("/relations", {
      from_id: params.groupId,
      from_type: "GROUP",
      to_id: userId,
      to_type: "USER",
      relation_type_group: "COMMON",
    });
  }

  async function addAssetToGroup(assetId) {
    const groupId = params.groupId;
    var res = await put(`/assets/${assetId}/group/${groupId}`);
  }

  async function removeUserFromGroup(userId) {
    const relation = await get("/relations", {
      from_id: params.groupId,
      from_type: "GROUP",
      to_id: userId,
      to_type: "USER",
    });
    if (relation && relation.results && relation.results.length) {
      const toDelete = relation.results[0].id;
      await destroy(`/relations/${toDelete}`);
    }
  }

  async function removeAssetFromGroup(assetId) {
    var res = await destroy(
      `/assets/${assetId}/group/${Number(params.groupId)}`
    );
  }

  function renderAssetCounter() {
    if (groupAssets != undefined && groupAssets != null) {
      return "(" + groupAssets.length + ")";
    }
  }

  function renderUsersCounter() {
    if (groupUsers != undefined && groupUsers != null) {
      return "(" + groupUsers.length + ")";
    }
  }

  function groupHandler(value, reason, detail) {
    setLoading(true);
    var item = detail?.option;
    if (reason == "removeOption") {
      var justAdded = assetsToAdd?.find((x) => x.id == item.id);
      if (!justAdded) {
        setAssetsToRemove([...assetsToRemove, detail?.option]);
      } else {
        setAssetsToAdd(assetsToAdd.filter((x) => x.id != item.id));
      }

      var noGroup = nonGroupAssets;
      if (!noGroup?.find((x) => x.id == item.id)) {
        noGroup?.push(detail?.option);
      }

      setNonGroupAssets(noGroup);
      setGroupAssets(groupAssets.filter((x) => x.id != item.id));
    }
    if (reason == "selectOption") {
      var item = detail?.option;
      //check if existing in removed group
      var currentAssets = groupAssets;
      var justRemoved = assetsToRemove?.find((x) => x.id == item.id);
      if (!justRemoved) {
        item.new = true;
        setAssetsToAdd([...assetsToAdd, item]);
      } else {
        setAssetsToRemove(assetsToRemove.filter((x) => x.id != item.id));
      }
      currentAssets.push(item);
      setGroupAssets(currentAssets);
    }
    setLoading(false);
  }

  function userHandler(value, reason, detail) {
    setLoading(true);
    var item = detail?.option;
    if (reason == "removeOption") {
      var toRemove = usersToRemove;

      var justAdded = usersToAdd?.find((x) => x.id == item.id);
      if (!justAdded) {
        setUsersToRemove([...usersToRemove, detail?.option]);
      } else {
        setUsersToAdd(usersToAdd.filter((x) => x.id != item.id));
      }

      var noGroup = nonGroupUsers;
      if (!noGroup?.find((x) => x.id == item.id)) {
        noGroup?.push(detail?.option);
      }
      setNonGroupUsers(noGroup);
      setGroupUsers(groupUsers.filter((x) => x.id != item.id));
    }
    if (reason == "selectOption") {
      var item = detail?.option;
      //check if existing in removed group
      var currentUsers = groupUsers;
      var justRemoved = usersToRemove?.find((x) => x.id == item.id);
      if (!justRemoved) {
        item.new = true;
        setUsersToAdd([...usersToAdd, item]);
      } else {
        setUsersToRemove(usersToRemove.filter((x) => x.id != item.id));
      }
      currentUsers?.push(item);
      setGroupUsers(currentUsers);
    }
    setLoading(false);
  }

  function renderDisplayType() {
    switch (displayContent) {
      case GROUP_USERS:
        return (
          <>
            <FrameHeader
              title={"Users in this group " + renderUsersCounter()}
            />
            <>
              <Autocomplete
                multiple
                id="tags-standard"
                filterSelectedOptions
                readOnly={!isGroupAdmin}
                options={nonGroupUsers}
                getOptionLabel={(option) =>
                  option.first_name + " " + option.last_name
                }
                onChange={(event, value, reason, detail) => {
                  userHandler(value, reason, detail);
                }}
                renderInput={(params, index) => (
                  <>
                    <TextField
                      key={index}
                      {...params}
                      variant="standard"
                      placeholder={!isGroupAdmin ? "" : "Enter name"}
                    />
                  </>
                )}
                renderTags={(tagValue, getTagProps) => {
                  return tagValue?.map((option, index) =>
                    option.new ? (
                      <AddNewChip
                        key={index}
                        {...getTagProps({ index })}
                        label={option.first_name + " " + option.last_name}
                      />
                    ) : (
                      <Chip
                        {...getTagProps({ index })}
                        label={option.first_name + " " + option.last_name}
                      />
                    )
                  );
                }}
                value={groupUsers}
                filterOptions={(options, params) => {
                  try {
                    const filtered = filter(options, params);
                    return filtered;
                  } catch (e) {
                    return new []();
                  }
                }}
              />
              <Space size="m" />
              <Label text="Primary contact">
                <Select
                  value={formState.values.primary_contact_id}
                  onChange={(e) =>
                    formState.setField("primary_contact_id", e.target.value)
                  }
                  disabled={groupUsers && groupUsers?.length == 0}
                >
                  {groupUsers?.map((user) => {
                    return (
                      <option value={user.id}>
                        {user.first_name + " " + user.last_name}
                      </option>
                    );
                  })}
                </Select>
              </Label>
            </>
          </>
        );

      case GROUP_ASSETS:
        return (
          <>
            <FrameHeader
              title={"Assets in this group " + renderAssetCounter()}
            />
            <>
              <Autocomplete
                multiple
                id="tags-standard"
                filterSelectedOptions
                options={nonGroupAssets}
                readOnly={!isGroupAdmin}
                getOptionLabel={(option) => option.name}
                onChange={(event, value, reason, detail) => {
                  groupHandler(value, reason, detail);
                }}
                renderInput={(params, index) => (
                  <TextField
                    key={index}
                    {...params}
                    variant="standard"
                    placeholder={!isGroupAdmin ? "" : "Enter name"}
                  />
                )}
                renderTags={(tagValue, getTagProps) => {
                  return tagValue?.map((option, index) =>
                    option.new ? (
                      <AddNewChip
                        key={index}
                        {...getTagProps({ index })}
                        label={option.name}
                      />
                    ) : (
                      <Chip
                        {...getTagProps({ index })}
                        key={index}
                        label={option.name}
                      />
                    )
                  );
                }}
                value={groupAssets}
                filterOptions={(options, params) => {
                  try {
                    const filtered = filter(options, params);
                    return filtered;
                  } catch (e) {
                    return new []();
                  }
                }}
              />
            </>
          </>
        );
    }
  }

  return loading ? (
    <LoadingFrame />
  ) : (
    <>
      <Space size="l">
        <FrameHeader title="Info" />
        <Frame>
          <Label text="Group name">
            <TextInput {...text("name")} disabled={!isGroupAdmin} required />
          </Label>

          <Space />
          <div className={styles.break} />
          <InputRow>
            <Button
              selected={displayContent == GROUP_ASSETS}
              icon="map"
              onClick={() => setDisplayContent(GROUP_ASSETS)}
            >
              Assets
            </Button>
            <Button
              selected={displayContent == GROUP_USERS}
              icon="person"
              disabled={!isGroupAdmin}
              onClick={() => setDisplayContent(GROUP_USERS)}
            >
              Users
            </Button>
          </InputRow>

          <Space>{renderDisplayType()}</Space>
          <FrameFooter>
            {isGroupAdmin && (
              <Button
                type="submit"
                theme="primary"
                disabled={!valid || !saveRequired()}
                isLoading={saving}
                onClick={save}
              >
                Save
              </Button>
            )}
          </FrameFooter>
        </Frame>
      </Space>

      {isGroupAdmin && (
        <div className={styles.deleteWrap}>
          <Button
            onClick={deleteGroupConfirmaton}
            theme="danger"
            isLoading={deleting}
          >
            Delete group
          </Button>
        </div>
      )}
    </>
  );
}
